.follow-us {
    @media screen and (min-width: $large) {
        float: right;
        margin-top: -110px;
    }

    @media screen and (min-width: 1230px) {
        float: none;
        margin: 0;
    }

    h2 {
        @include fontsize(24 24 1);

        text-transform: uppercase;
        font-family: $rift !important;
    }

    .follow-form {
        max-width: none;
        white-space: nowrap;

        @media screen and (min-width: 768px) {
            max-width: 300px;
        }

        label {
            @include fontsize(18 24 1);

            display: block;
            margin-top: 25px;
            margin-bottom: 3px;
            color: #333;
            font-weight: 700;
            font-family: $myriad;
        }

        input {
            display: inline-block;
            float: none;
            padding: 0 0.75rem;
            width: 100%;
            height: 2.1875rem;
            border: 0;
            border-radius: 5px;
            background: #f2f2f2;
            color: #666;
            vertical-align: middle;
            line-height: 100%;

            &.last-input {
                margin-right: 10px;
                width: calc(100% - 70px);
            }
        }
    }
}

.subpage .main-content {
    .newsletter-header {
        .fa {
            margin-right: 5px;
        }
    }

    .newsletter-signup {
        position: relative;
        margin-bottom: 90px;
        padding: 30px 0;
        border-right: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
        text-align: center;

        &:before,
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 5px;
            background: transparent url('../images/heading-line-border.gif') repeat-x center left;
            content: '';
        }

        &:after {
            top: auto;
            bottom: 0;
        }

        h3 {
            @include fontsize(24 24 0);

            margin: 0 !important;
            color: #333;
            font-weight: 600;
        }

        hr {
            margin: 30px 0;
        }

        .signup-form {
            display: flex;
            padding: 0 25px;

            .form-field {
                margin-right: 25px;
                width: 33%;
                text-align: left;

                label {
                    @include fontsize(18 24 1);

                    margin-bottom: 3px;
                    color: #333;
                    font-weight: 700;
                    font-family: $myriad;
                }

                input {
                    padding: 10px;
                    background: #f2f2f2;
                }
            }
        }

        .follow-btn {
            position: relative;
            display: inline-block;
            margin-left: 35px;
            width: 60px;
            height: 40px;
            border-radius: 0 10px 10px 0;
            background: #e6e6e6;
            color: #244a74;
            vertical-align: middle;
            letter-spacing: normal;
            font-size: 1.5rem;
            line-height: 2.25rem;

            align-self: flex-end;

            .fa-angle-right {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
    }
}

//Mail Chimp Overrides
#mc_embed_signup,
#mc_embed_signup input,
#mc_embed_signup textarea,
#mc_embed_signup select,
#mc_embed_signup button {
    font-family: $myriad !important;

    &[type='submit'] {
        font-family: $rift !important;
    }
}
