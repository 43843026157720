$base-font-size: 100%;
// assets path
$assets: '';
// colors
$primary-color: #1a6593;

// fonts
$myriad: "Myriad Pro", "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
$myriad-semi: "myriad-pro-semi-condensed", "Gill Sans", "Gill Sans MT", Calibri, sans-serif;
$rift: 'Rift', Arial Narrow, Arial, sans-serif;
$marydale: 'Marydale', Brush Script MT, cursive;

// global settings
$body-spacing-mobile: 25px;
$body-spacing-offset-mobile: -25px;
$body-spacing-desktop: 60px;
$body-spacing-offset-desktop: -60px;