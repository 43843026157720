.about-us .main-content> h3 {
    margin-bottom: rem-calc(50px);
    padding-bottom: rem-calc(15px);
    background: transparent url(/themes/CRN_Location/assets/dist/images/heading-line-border.gif) repeat-x bottom left;
}

.our-team {
    display: flex;
    max-width: 880px;

    flex-wrap: wrap;

    .team-member {
        @include four-col();

        overflow: hidden;
        width: 100%;

        .profile-pic {
            position: relative;
            overflow: hidden;
            margin: 0;
            width: 100%;

            &:after {
                position: absolute;
                right: 0;
                bottom: 0;
                z-index: 6;
                display: block;
                width: 25%;
                height: 7px;
                border-top: 2px solid #fff;
                border-left: 2px solid #fff;
                background: #c7c7c7;
                content: '';
            }

            &:before {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 5;
                width: 100%;
                height: 7px;
                border-top: 2px solid #fff;
                background: #e6e6e6;
                content: '';
            }

            img {
                position: static;
                display: block;
                margin: 0 auto;
                max-width: 100%;
                max-height: 150%;
            }
        }

        h3.member-name {
            margin-top: rem-calc(20px);
            margin-bottom: rem-calc(8px);
            line-height: 0.8em;

            a {
                @include fontsize(22 22 1);

                color: #1a6593;
                text-decoration: none;
                font-weight: bold;
                font-family: $myriad;
                line-height: 0.8em;

                &:after {
                    content: none;
                }
            }
        }

        .member-title {
            @include fontsize(16 18 100);

            color: #666;
            font-family: $rift;
        }
    }
}

.network-consulting {
    .team-member {
        display: none;

        &[data-department*='Network Consulting & Retreat Planning'] {
            display: block;
        }
    }
}

.property-management {
    .team-member {
        display: none;

        &[data-department*='On-Site Property Management & Conference Services'] {
            display: block;
        }
    }
}

.profile-detail {
    display: flex;

    flex-wrap: wrap;

    @media (min-width: $large) {
        flex-wrap: nowrap;
    }

    &:after {
        display: table;
        clear: both;
        content: '';
    }

    .profile-left {
        margin-right: 60px;
        margin-left: 0;
        max-width: 100%;

        @media (min-width: $medium) {
            max-width: 50%;
        }

        @media (min-width: $large) {
            min-width: 35%;
        }

        @media (min-width: $xlarge) {
            max-width: 350px;

            flex-grow: 10;
        }
    }

    .profile-right {
        @media (min-width: $xlarge) {
            flex-shrink: 10;
        }
    }

    img.profile-image {
        margin: 0;
        width: 100%;
    }

    h3.name {
        margin-top: 10px;
    }
}

.btn.btn-primary.our-team {
    float: right;
    padding: 20px 40px;
    width: auto;
}
