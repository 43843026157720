.main-content .album {
    display: inline-block;

    .photos {
        position: relative;
        display: flex;
        width: 270px;
        height: 240px;

        justify-content: flex-start;

        &:before {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 12;
            width: 30px;
            height: 25px;
            background: transparent url('/themes/CRN_Location/assets/dist/images/gallery-icon.png') top left no-repeat;
            background-size: 100%;
            content: '';
        }

        &:after {
            position: absolute;
            right: 0;
            bottom: -6px;
            z-index: 2;
            width: 25%;
            height: 5px;
            border-left: 1px solid #fff;
            background-color: #c5c5c5;
            content: '';
        }

        .photo {
            margin: 0;
            width: calc(100% / 2);
            height: 120px;

            &:nth-child(1n + 5) {
                display: none;
            }

            a,
            img {
                position: absolute;
                min-width: 100%;
                min-height: 100%;
            }
        }
    }
}

.gallery-overlay-text {
    @include fontsize(24 30 40);

    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 3;
    color: #fff;
    text-transform: uppercase;
    font-family: $rift;
}

.album {
    height: 240px;

    &:before {
        position: absolute;
        top: -6px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #e6e6e6;
        content: '';
    }

    &:after {
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 5px;
        background-color: #e6e6e6;
        content: '';
    }
}

.gallery,
.album {
    > h2,
    > p {
        display: none !important;
    }

    .photos .photo img,
    .photos .photo .image {
        margin: 0;
    }
}
