.subpage .main-content .plan-event {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    border-radius: 25px;
    text-align: center;

    @media screen and (min-width: $medium) {
        display: flex;
        min-height: 175px;
        width: 100%;
    }

    .title-container {
        position: relative;

        @media screen and (min-width: $medium) {
            overflow: hidden;
            min-width: 270px;
        }
    }

    .content-container {
        @media screen and (min-width: $medium) {
            overflow: hidden;
            padding-bottom: 30px;
            border: 2px solid #e6e6e6;
            border-left: 0;
            border-radius: 0 25px 25px 0;
            text-align: left;
        }
    }

    img {
        margin: 0;
        width: 100%;

        @media screen and (min-width: $medium) {
            width: auto;
            height: 100%;
        }
    }

    .calendar-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 70px;
        height: 60px;
        transform: translateY(-90%) translateX(-50%);
    }

    .plan-content {
        margin-top: -8px;
        padding: 20px 25px 40px;
        border-right: 1px solid #d2d2d2;
        border-left: 1px solid #d2d2d2;

        @media screen and (min-width: $medium) {
            padding-top: 40px;
            padding-bottom: 15px;
            padding-left: 40px;
            border: 0;
        }
    }

    h3 {
        @include fontsize(24 30 40);

        position: absolute;
        top: 50%;
        margin: 0;
        width: 100%;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-family: $rift, sans-serif;
        transform: translateY(50%);
    }

    p {
        @include fontsize(16 24 0);

        margin: 0;
        font-style: italic;
        font-family: $myriad;
    }

    .btn.btn-primary {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: -10%;
        padding: 20px 0;
        width: 120%;
        line-height: 15px;

        @media screen and (min-width: $medium) {
            display: inline-block;
            margin: 0 40px;
            padding: 15px 35px;
            width: auto;
        }
    }
}

.subpage article.sidebar .plan-event {
    position: relative;
    overflow: hidden;
    margin: 90px auto 0;
    width: 100%;
    border-radius: 25px;
    text-align: center;

    @media (min-width: $large) {
        width: calc(100% - 120px);
    }

    .calendar-icon {
        position: absolute;
        top: 20px;
        left: 50%;
        width: 70px;
        height: 60px;
        transform: translateX(-50%);
    }

    .plan-content {
        margin-top: -8px;
        padding: 20px 25px 40px;
        border-right: 1px solid #d2d2d2;
        border-left: 1px solid #d2d2d2;
    }

    h3 {
        @include fontsize(24 30 40);

        position: absolute;
        top: 93px;
        margin: 0;
        width: 100%;
        color: #fff;
        text-align: center;
        text-transform: uppercase;
        font-family: $rift, sans-serif;
    }

    p {
        @include fontsize(16 24 0);

        margin: 0;
        font-style: italic;
        font-family: $myriad;
    }

    img {
        width: 100%;
    }

    .btn.btn-primary {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: -10%;
        padding: 20px 0;
        width: 120%;
        line-height: 15px;
    }
}
