.subpage .main-content {
    h3.recreation-title {
        margin-bottom: 35px;
        padding-bottom: 20px;
        border-bottom: 2px solid #e6e6e6;
    }
}

.recreation-content-container {
    display: flex;

    .recreation-content {
        width: calc(100% - 240px);
    }
}

.image-gallery {
    position: relative;
    height: 240px;
}
