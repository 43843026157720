.content-form {
  position: relative;
  overflow: hidden;
  // margin-right: -15px;
  margin-right: -$gutter / 2;
  margin-left: -$gutter / 2;

  h2,
  .form-header,
  .form-footer {
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;

    p:empty {
      margin: 0;
    }
  }

  p {
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
    clear: both;

    &:empty {
      display: none;
    }
  }

  hr {
    margin-left: $gutter / 2;
    width: calc(100% - $gutter);
    clear: both;
  }

  .form-footer {
    margin-top: 30px;
  }

  .validation-summary {
    display: none;
  }

  fieldset {
    border: 0;
    margin: 0 0 30px 0;
    padding: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  label {
    position: relative;
    display: inline-block;
    clear: both;
  }

  input[type='text'],
  input[type='password'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='week'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  textarea,
  select {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 0 30px 0;
    appearance: none;

    &:active,
    &:focus {
      outline: 0;
    }
  }

  textarea {
    height: auto;
    min-height: 120px;
  }

  select::-ms-expand {
    display: none;
  }

  .error-msg {
    position: relative;
    top: -30px;
    max-width: 100%;
    margin-top: 0;
    color: red;
  }

  .required {
    label:after {
      position: relative;
      top: -7px;
      margin-left: 2px;
      content: '\f069';
      text-decoration: inherit;
      font-family: FontAwesome;
      font-size: 6px;
      font-weight: normal;
      font-style: normal;
    }
  }

  .checkbox {
    overflow: hidden;
  }

  input[type='submit'] {
    margin-left: $gutter / 2;
    border: 0;
  }

  /* recaptcha */
  .g-recaptcha {
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
    margin: 0 0 30px 0;
  }

  /* small screens */
  .sm-full {
    float: left;
    clear: none;
    width: 100%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  .sm-half {
    float: left;
    clear: none;
    width: 50%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  .sm-third {
    float: left;
    clear: none;
    width: 33.333%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  .sm-two-thirds {
    float: left;
    clear: none;
    width: 66.666%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  .sm-quarter {
    float: left;
    clear: none;
    width: 25%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  .sm-three-quarters {
    float: left;
    clear: none;
    width: 75%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
  }

  .sm-last + div {
    clear: both;
  }
  /* medium screens */
  @media screen and (min-width: 40rem) {
    .sm-last + div {
      clear: none;
    }

    .md-full {
      float: left;
      clear: none;
      width: 100%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .md-half {
      float: left;
      clear: none;
      width: 50%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .md-third {
      float: left;
      clear: none;
      width: 33.333%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .md-two-thirds {
      float: left;
      clear: none;
      width: 66.666%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .md-quarter {
      float: left;
      clear: none;
      width: 25%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .md-three-quarters {
      float: left;
      clear: none;
      width: 75%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .md-last + div {
      clear: both;
    }
  }
  /* large screens */
  @media screen and (min-width: 64rem) {
    .sm-last + div {
      clear: none;
    }

    .md-last + div {
      clear: none;
    }

    .lg-full {
      float: left;
      clear: none;
      width: 100%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .lg-half {
      float: left;
      clear: none;
      width: 50%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .lg-third {
      float: left;
      clear: none;
      width: 33.333%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .lg-two-thirds {
      float: left;
      clear: none;
      width: 66.666%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .lg-quarter {
      float: left;
      clear: none;
      width: 25%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .lg-three-quarters {
      clear: none;
      width: 75%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }

    .lg-last + div {
      clear: both;
    }
  }
}
