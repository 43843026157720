// GRID SETTINGS
$column: 90px;
$gutter: 0;
$grid-columns: 12;
$max-width: 100%;

// BREAKPOINTS
$medium: 640px;
$large: 1240px;
$xlarge: 1341px;
$xxlarge: 1400px;
